<template>
    <g>
        <g
            v-bind:font-family='styles.labels["font-family"]'
        >
            <text
                v-for='label in labels'
                :key='label.value'
                v-bind:transform='build_transform_for_label(label)'
                v-bind:fill='styles.labels.fill'
                dominant-baseline='text-top'
                text-anchor='middle'
            >{{ label.text }}</text>
        </g>
        <g>
            <line
                v-for='gridline in gridlines'
                :key='gridline.value'
                v-bind:x1='x_scale(gridline.value)'
                v-bind:x2='x_scale(gridline.value)'
                v-bind:y1='0'
                v-bind:y2='dimensions.height'
                v-bind:stroke='build_stroke_for_gridline(gridline).color'
                v-bind:stroke-width='build_stroke_for_gridline(gridline).width'
            />
        </g>
    </g>
</template>

<script>
    import { scaleLinear } from 'd3-scale';

    export default {
        name: 'graph_x_axis',
        props: [
            'dimensions',
            'x_domain',
            'x_range',
            'gridlines',
        ],
        data() {
            return {
                styles: {
                    labels: {
                        fill: 'white',
                        'font-family': 'sans-serif',
                    },
                    gridlines: {
                        stroke: {
                            color: 'gray',
                            width: 1,
                        },
                    },
                },
            };
        },
        computed: {
            labels() {
                return this.gridlines.slice();
            },
            x_scale() {
                return scaleLinear()
                    .domain(this.x_domain)
                    .range(this.x_range);
            },
        },
        methods: {
            build_transform_for_label(label) {
                const x = this.x_scale(label.value);
                const y = this.dimensions.height + 20;
                return `translate(${x}, ${y})`;
            },
            build_stroke_for_gridline(gridline) {
                return {
                    width: gridline.style && gridline.style.stroke && gridline.style.stroke.width
                           || this.styles.gridlines.stroke.width,
                    color: gridline.style && gridline.style.stroke && gridline.style.stroke.color
                           || this.styles.gridlines.stroke.color,
                };
            },
        },
    }
</script>

<style scoped>
</style>

