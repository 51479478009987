<template>
    <div>
        Logging out...
    </div>
</template>

<script>
    import {
        logout,
    } from './auth.js';

    export default {
        name: 'Logout',
        mounted() {
            logout();
        },
    }
</script>

<style scoped>
</style>

