<template>
    <g v-html='text_tag.outerHTML' /> 
</template>

<script>
    import build_text from './build_text.js';

    export default {
        name: 'plot_text',
        props: ['fill', 'text', 'line_height', 'letter_spacing', 'x', 'y'],
        computed: {
            text_tag() {
                const {
                    text,
                    letter_spacing,
                    line_height,
                    x,
                    y,
                } = this.$props;
                return build_text({
                    text,
                    letter_spacing: parseFloat(letter_spacing),
                    line_height: parseFloat(line_height),
                    x,
                    y,
                });
            },
        },
    }
</script>

<style scoped>
</style>

