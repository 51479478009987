import auth0_sdk from 'auth0-js';

export {
    redirect_to_auth0_login,
    get_auth_result_from_hash,
    store_authentication_token,
    clear_authentication_token,
    get_authentication_token,
    store_desired_url,
    redirect_to_desired_url,
    respond_to_requires_authentication,
    logout,
};

const auth0 = new auth0_sdk.WebAuth({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
});

function redirect_to_auth0_login() {
    auth0.authorize({
        responseType: 'id_token',
        redirectUri: process.env.VUE_APP_URI_TO_AUTHORIZE_ACTOR 
    });
}

function get_auth_result_from_hash(hash) {
    return new Promise((resolve, reject) => {
        auth0.parseHash({ hash }, function (error, auth_result) {
            if (error) {
                reject(error);
            } else {
                resolve(auth_result);
            }
        })
    });
}

function store_authentication_token(token) {
    const key = get_local_storage_key();
    localStorage.setItem(key, token);
}

function get_local_storage_key() {
    return 'auth0_token';
}

function clear_authentication_token() {
    const key = get_local_storage_key();
    localStorage.removeItem(key);
}

function get_authentication_token() {
    const key = get_local_storage_key();
    const token = localStorage.getItem(key);
    return token;
}

function store_desired_url(desired_url) {
    const key = get_desired_url_key();
    localStorage.setItem(key, desired_url);
}

function get_desired_url_key() {
    return 'auth|desired_url';
}

function redirect_to_desired_url() {
    const key = get_desired_url_key();
    try {
        const url = new URL(localStorage.getItem(key));
        if (url.host !== window.location.host) {
            window.location = '/';
            return;
        }
        window.location = url;
    } catch (error) {
        window.location = '/';
    }
}

function respond_to_requires_authentication() {
    clear_authentication_token();
    window.location = process.env.VUE_APP_LOGIN_URL;
}

function logout() {
    auth0.logout({
        returnTo: process.env.VUE_APP_CLEAR_AUTHENTICATION_TOKEN_URL,
        clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
    });
}

