<template>
    <g
        v-bind:transform='transformation'
    >
        <plot_points
            v-for='graph in graphs'
            v-bind:key='graph.name'
            v-bind:type='graph.type'
            v-bind:x_domain='x_domain'
            v-bind:x_range='x_range'
            v-bind:y_domain='graph.y_domain'
            v-bind:y_range='y_range'
            v-bind:y0='graph.y0'
            v-bind:y1='graph.y1'
            v-bind:points='build_points_for_graph(graph)'
            v-bind:stroke='graph.stroke'
            v-bind:fill='graph.fill'
        />
        <rect
            stroke='white'
            stroke-width='1'
            fill='none'
            v-bind:width='dimensions.width'
            v-bind:height='dimensions.height'
        />
    </g>
</template>

<script>
    import plot_points from '../graphing/plot_points.vue';

    export default {
        name: 'ConditionsGraph',
        components: {
            plot_points,
        },
        props: ['forecast', 'time_range', 'dimensions'],
        computed: {
            graphs() {
                return [
                    {
                        name: 'Sunshine',
                        type: 'area',
                        stroke: 'none',
                        fill: '#EEDC82',
                        data: this.forecast.sky_cover_percentage,
                        y_domain: this.sky_cover_y_domain,
                        x: prediction => prediction.start_timestamp,
                        y: prediction => prediction.sky_cover_percentage,
                        y1: this.y_range[0],
                    },
                    {
                        name: 'Clouds',
                        type: 'area',
                        stroke: 'none',
                        fill: '#EBF6FF',
                        data: this.forecast.sky_cover_percentage,
                        y_domain: this.sky_cover_y_domain,
                        x: prediction => prediction.start_timestamp,
                        y: prediction => prediction.sky_cover_percentage,
                        y0: this.y_range[1],
                    },
                    {
                        name: 'Precipitation',
                        type: 'area',
                        stroke: 'none',
                        fill: '#6087C0', // use #BC5BD7 for snow
                        data: this.forecast.probability_of_precipitation,
                        y_domain: this.precipitation_y_domain,
                        x: prediction => prediction.start_timestamp,
                        y: prediction => prediction.probability_of_precipitation,
                        y0: this.y_range[1],
                    },
                ];
            },
            x_domain() {
                return [
                    this.time_range.start_timestamp,
                    this.time_range.end_timestamp,
                ];
            },
            x_range() {
                return [0, this.dimensions.width];
            },
            sky_cover_y_domain() {
                return [0, 100];
            },
            precipitation_y_domain() {
                return [0, 100];
            },
            y_range() {
                return [0, this.dimensions.height];
            },
            transformation() {
                const {x, y} = this.dimensions;
                return `translate(${x}, ${y})`;
            },
        },
        methods: {
            build_points_for_graph(graph) {
                return graph.data.map(data_point => ({
                    x: graph.x(data_point),
                    y: graph.y(data_point),
                }));
            },
        },
    }
</script>

<style scoped>
</style>

