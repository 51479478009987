export default function convert_gpx_string_into_gps_track(gpx_string) {
    const dom_parser = new DOMParser();
    const xml_document = dom_parser.parseFromString(gpx_string, 'text/xml');

    const track_element = xml_document.querySelector('gpx trk');
    const name_element = track_element.querySelector('name');
    const track_points_elements = track_element.querySelectorAll('trkseg trkpt');

    const gps_track = {
        name: name_element.textContent,
        track_points: transform_track_points_elements_into_track_points(track_points_elements),
    };

    return populate_metadata(gps_track);
}

function transform_track_points_elements_into_track_points(track_points_elements) {
    window.elements = track_points_elements;
    const track_points = [];
    for (let index=0; index<track_points_elements.length; index++) {
        const element = track_points_elements[index];
        const track_point = {
            latitude: parseFloat(element.getAttribute('lat')),
            longitude: parseFloat(element.getAttribute('lon')),
            elevation: parseFloat(element.querySelector('ele').textContent),
            timestamp: new Date(element.querySelector('time').textContent).getTime(),
        };
        track_points.push(track_point);
    }
    return track_points;
}

function populate_metadata(gps_track) {
    const { track_points = [] } = gps_track;
    return {
        ...gps_track,
        bounds: {
            minimum_latitude: Math.min.apply(null, track_points.map(p => p.latitude)),
            minimum_longitude: Math.min.apply(null, track_points.map(p => p.longitude)),
            maximum_latitude: Math.max.apply(null, track_points.map(p => p.latitude)),
            maximum_longitude: Math.max.apply(null, track_points.map(p => p.longitude)),
        },
        duration_in_milliseconds: determine_duration(gps_track),
    };
}

function determine_duration(gps_track) {
    const { track_points = [] } = gps_track;
    const minimum_timestamp = Math.min.apply(null, track_points.map(p => p.timestamp));
    const maximum_timestamp = Math.max.apply(null, track_points.map(p => p.timestamp));
    return maximum_timestamp - minimum_timestamp;
}

