<template>
    <div>
        Authenticate
    </div>
</template>

<script>
    import {
        get_auth_result_from_hash,
        store_authentication_token,
        redirect_to_desired_url,
    } from './auth.js';

    export default {
        name: 'Authenticate',
        mounted() {
            get_auth_result_from_hash(window.location.hash)
            .then(auth_result => store_authentication_token(auth_result.idToken))
            .then(redirect_to_desired_url)
            .catch(this.respond_to_error);
        },
        methods: {
            respond_to_error(error) {
                console.error(error);
            },
        },
    }
</script>

<style scoped>
</style>

