<template>
    <g v-bind:width='dimensions.width' v-bind:height='dimensions.height'>
        <rect
            v-bind:width='dimensions.width'
            v-bind:height='dimensions.height'
            fill='black'
        />
    </g>
</template>

<script>
    export default {
        name: 'Background',
        props: ['dimensions'],
    }
</script>

<style scoped>
</style>

