import pad_number_to_2_places from './pad_number_to_2_places.js';

export default function build_timestamp_string(timestamp) {
    const start_time = new Date(timestamp);
    const year = start_time.getFullYear();
    const month = pad_number_to_2_places(start_time.getMonth() + 1);
    const day = pad_number_to_2_places(start_time.getDate());
    const date = `${year}.${month}.${day}`;

    const hours = pad_number_to_2_places(start_time.getHours());
    const minutes = pad_number_to_2_places(start_time.getMinutes());
    const time = `${hours}:${minutes}`;

    return `${date} at ${time}`;
}

