<template>
    <p>Checking...</p>
</template>

<script>
    import integrate_event_into_system from 'src/tools/integrate_event_into_system.js';

    export default {
        name: 'ViewAuthStatus',
        mounted() {
            integrate_event_into_system({
                system_path: ['auth', 'is_authenticated'],
            }).then(response => {
                console.log(response);
            });
        },
    }
</script>

<style scoped>
</style>

