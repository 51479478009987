import integrate_event_into_system from 'src/tools/integrate_event_into_system.js';
import { respond_to_requires_authentication } from 'src/components/auth/auth.js';

export default async function redirect_if_not_authenticated() {
    return integrate_event_into_system({ system_path: ['auth', 'is_authenticated'] })
    .then(response => {
        if (response.status === 401) {
            respond_to_requires_authentication();
        }
    })
    .catch(respond_to_requires_authentication);
}
