import font from './sans-1_stroke.js';

const XMLNS = "http://www.w3.org/2000/svg";


export default function build_text(input) {
    const {
        text = '',
        line_height = 28,
        x = 0,
        y = 0,
    } = input;
    const letter_spacing = (typeof input.letter_spacing === 'undefined'
                            ? line_height / 2.1
                            : input.letter_spacing);
    return build_line({ text, line_height, letter_spacing, x, y, });
}

function build_line({ text, line_height, letter_spacing, x, y, }) {
    let offset_x = 0;
    const g = document.createElementNS(XMLNS, 'g');

    for (let letter_index=0; letter_index<text.length; letter_index++) {
        const character = text[letter_index];

        if (character === ' ') {
            offset_x += letter_spacing * 1;
        } else {
            offset_x += build_before_character_offset({ character, letter_spacing });
            const letter = build_letter(character);
            letter.setAttribute(
                'transform',
                `translate(${x + offset_x}, ${y}) scale(${build_scale_factor(line_height)})`
            );
            offset_x += build_after_character_offset({ character, letter, letter_spacing });
            g.appendChild(letter);
        }
    }

    return g;
}

function build_letter(letter) {
    const character_index = letter.charCodeAt(0) - 33;
    const path = document.createElementNS(XMLNS, 'path');
    path.setAttribute('d', font.characters[character_index].d)
    path.setAttribute('fill', 'none')
    path.setAttribute('stroke', 'black');
    path.setAttribute('stroke-width', '1');
    path.setAttribute('stroke-linecap', 'round');
    path.setAttribute('stroke-linejoin', 'round');
    return path;
}

function build_scale_factor(line_height) {
    return line_height / 32; // Calculated using all characters in font
}

const character_offsets = {
    '1': {
        before: 0,
        after: 1,
    },
    '2': {
        after: 2,
    },
    'f': {
        after: -3.5,
    },
    'i': {
        before: 1,
        after: -5,
    },
    'l': {
        before: 2,
        after: -6,
    },
    'm': {
        after: 7,
    },
    'r': {
        after: -3,
    },
    't': {
        before: 0,
        after: -2,
    },
    'w': {
        after: 1,
    },
    'B': {
        after: 2,
    },
    'D': {
        after: 1,
    },
    'G': {
        after: 3,
    },
    'I': {
        after: -4,
    },
    'M': {
        after: 4,
    },
    'N': {
        after: 2,
    },
    'P': {
        after: 2,
    },
    'S': {
        after: 2,
    },
    'W': {
        after: 4,
    },
    '.': {
        before: 3,
        after: -10,
    },
};

function build_before_character_offset({ character }) {
    // l, i, r, ., M, N, A, P, W, t
    const offsets = character_offsets[character];
    if (!offsets) return 0;
    if (typeof offsets.before !== 'undefined') {
        return offsets.before;
    } else {
        return 0;
    }
}

function build_after_character_offset({ character, letter, letter_spacing }) {
    let offset = 0;
    offset += letter.getBBox().width + letter_spacing;
    const offsets = character_offsets[character];
    if (!offsets) return offset;
    if (typeof offsets.after !== 'undefined') {
        return offset + offsets.after;
    } else {
        return offset;
    }
}

