<template>
    <div>
        Clearing Authentication Token...
    </div>
</template>

<script>
    import {
        clear_authentication_token,
    } from './auth.js';

    export default {
        name: 'ClearAuthenticationToken',
        mounted() {
            clear_authentication_token();
            window.location = '/';
        },
    }
</script>

<style scoped>
</style>

