<template>
    <g>
        <g
            ref='labels'
            v-bind:font-family='styles.labels["font-family"]'
        >
            <text
                v-for='label in labels'
                :key='label.value'
                v-bind:transform='build_transform_for_label(label)'
                v-bind:fill='styles.labels.fill'
                dominant-baseline='middle'
                text-anchor='end'
            >{{ label.text }}</text>
        </g>
        <g>
            <line
                v-for='gridline in gridlines'
                :key='gridline.value'
                v-bind:x1='0'
                v-bind:x2='dimensions.width'
                v-bind:y1='y_scale(gridline.value)'
                v-bind:y2='y_scale(gridline.value)'
                v-bind:stroke='styles.gridlines.stroke.color'
                v-bind:stroke-width='styles.gridlines.stroke.width'
            />
        </g>
    </g>
</template>

<script>
    import { scaleLinear } from 'd3-scale';

    export default {
        name: 'graph_y_axis',
        props: [
            'dimensions',
            'y_domain',
            'y_range',
            'gridlines',
            'maximum_label_width',
        ],
        data() {
            return {
                styles: {
                    gridlines: {
                        stroke: {
                            color: 'gray',
                            width: 1,
                        },
                    },
                    labels: {
                        fill: 'white',
                        'font-family': 'sans-serif',
                    },
                },
                widest_label: null,
            };
        },
        computed: {
            labels() {
                return this.gridlines.slice();
            },
            y_scale() {
                return scaleLinear()
                    .domain(this.y_domain)
                    .range(this.y_range.slice().reverse());
            },
        },
        methods: {
            build_transform_for_label(label) {
                const x = -5;
                const y = this.y_scale(label.value);
                const translate = `translate(${x}, ${y})`;
                const scale = this.build_scale_for_label();
                return `${translate} ${scale}`;
            },
            build_scale_for_label() {
                if (this.widest_label === null) {
                    return '';
                }
                if (typeof this.maximum_label_width === 'undefined') {
                    return '';
                }
                const scale_factor = this.maximum_label_width / this.widest_label;
                if (scale_factor > 1) {
                    return '';
                }
                return `scale(${scale_factor})`;
            },
            set_widest_label() {
                const label_group = this.$refs.labels;
                const labels = [].slice.call(label_group.children);
                const label_widths = labels.map(
                    label => label.getBBox().width
                );
                this.widest_label = Math.max.apply(null, label_widths);
            },
        },
        mounted() {
            this.set_widest_label();
        },
    }
</script>

<style scoped>
</style>



