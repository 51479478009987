<template>
    <div class='container'>
        <Define_Plan
            v-on:update_plan='respond_to_updated_plan'
        />
        <View_Plan_as_SVG
            v-if='plan !== null'
            v-bind:title='plan.title'
            v-bind:habits='plan.habits'
            v-bind:goals='plan.goals'
        />
    </div>
</template>

<script>
    import Define_Plan from './Define_Plan.vue';
    import View_Plan_as_SVG from './View_Plan_as_SVG.vue';

    export default {
        name: 'Planner',
        components: {
            Define_Plan,
            View_Plan_as_SVG,
        },
        data() {
            return {
                plan: null,
            };
        },
        methods: {
            respond_to_updated_plan(plan) {
                this.plan = plan;
            },
        },
    }
</script>

<style scoped>
    /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
    .container {
        display: flex;
        flex-direction: row;
        padding: 1em;
    }
</style>

