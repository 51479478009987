<template>
    <div>
        <input v-model='date' placeholder='YYYY.MM.DD' />
        <input v-model='type' placeholder='TYPE' />
        <input v-model='title' placeholder='TITLE' />
        <input v-model='folder_id'>
        <button
            v-on:click='create_document'
            v-bind:disabled='is_create_button_disabled'
        >Create</button>
    </div>
</template>

<script>
    import integrate_event_into_system from 'src/tools/integrate_event_into_system.js';
    import redirect_if_not_authenticated from 'src/tools/redirect_if_not_authenticated.js';
    import { respond_to_requires_authentication } from 'src/components/auth/auth.js';

    export default {
        name: 'CreateDocument',
        data() {
            return {
                date: '',
                type: '',
                title: 'TITLE',
                folder_id: '',
                is_create_button_disabled: false,
            };
        },
        mounted() {
            redirect_if_not_authenticated();
            const {
                date, type, title, folder_id,
                create_document_on_load,
            } = this.$route.query;
            this.date = date || '';
            this.type = type || '';
            this.title = title || 'TITLE';
            this.folder_id = folder_id || '';
            if (create_document_on_load && this.are_attributes_set()) {
                this.create_document();
            }
        },
        methods: {
            are_attributes_set() {
                if (!this.date) {
                    return false;
                }
                if (!this.type) {
                    return false;
                }
                if (!this.folder_id) {
                    return false;
                }
                return true;
            },
            get_folders() {
                return [
                    { id: '1DVckwny4aRp8LX9IQdcKiHH1wxxeWN0q', name: 'Explorations' },
                ];
            },
            create_document() {
                this.is_create_button_disabled = true;
                integrate_event_into_system({
                    system_path: ['organizer', 'create_document_in_folder'],
                    input: {
                        date: this.date,
                        type: this.type,
                        title: this.title,
                        folder_id: this.folder_id,
                    },
                }).then(response => {
                    switch (response.status) {
                        case 200:
                            window.location = response.body.url;
                            break;
                        case 401:
                            respond_to_requires_authentication();
                            break;
                        default:
                            console.error(response);
                    }
                });
            }
        },
    }
</script>

<style scoped>
    div {
        margin: 1em;
    }

    input {
        display: block;
        margin-bottom: 1em;
        width: 300px;
    }
</style>

