<template>
    <p v-if='error'><b>Error loading run:</b> {{ error.message }}</p>
    <div v-else-if='run'>
        <table>
            <tbody>
                <tr><th>Timestamp</th><td>{{ build_timestamp_string(run.timestamp) }}</td></tr>
                <tr><th>Distance</th><td>{{ run.display.distance_in_kilometers }}</td></tr>
                <tr><th>Duration</th><td>{{ run.display.duration }}</td></tr>
                <tr><th>Speed</th><td>{{ run.display.speed }}</td></tr>
                <tr><th>Pace (km)</th><td>{{ run.display.pace_per_kilometer }}</td></tr>
                <tr><th>Pace (miles)</th><td>{{ run.display.pace_per_mile }}</td></tr>
            </tbody>
        </table>
        <GraphRuns
            v-bind:runs='[run]'
        />
    </div>
    <p v-else>Loading...</p>
</template>

<script>
    import GraphRuns from './GraphRuns.vue';

    import integrate_event_into_system from 'src/tools/integrate_event_into_system.js';
    import { respond_to_requires_authentication } from 'src/components/auth/auth.js';
    import build_timestamp_string from 'src/tools/build_timestamp_string.js';
    import convert_gpx_string_into_gps_track from '../organizer/trails/convert_gpx_string_into_gps_track.js';
    import get_gpx_string_for_trail from '../organizer/trails/get_gpx_string_for_trail.js';

    export default {
        name: 'ViewRun',
        components: {
            GraphRuns,
        },
        data() {
            return {
                run: null,
                error: null,
            }
        },
        computed: {
            dimensions() {
                return {
                    width: 500,
                    height: 250,
                };
            },
            points() {
                return this.run.speed_over_distance.map(
                    point => ({
                        x: point.distance,
                        y: point.speed,
                    })
                );
            },
            x_domain() { return [0, 5000]; },
            x_range()  { return [0, this.dimensions.width]; },
            y_domain() { return [3.0, 4.5]; },
            y_range()  { return [0, this.dimensions.height]; },
            x_gridlines() {
                return [
                    { text: '0km', value:    0 },
                    { text: '1km', value: 1000 },
                    { text: '2km', value: 2000 },
                    { text: '3km', value: 3000 },
                    { text: '4km', value: 4000 },
                    { text: '5km', value: 5000 },
                ];
            },
            y_gridlines() {
                return [
                    { text: '3.0 m/s', value: 3.0 },
                    { text: '3.5 m/s', value: 3.5 },
                    { text: '4.0 m/s', value: 4.0 },
                    { text: '4.5 m/s', value: 4.5 },
                ];
            },
        },
        mounted() {
            this.load_run();
        },
        methods: {
            build_timestamp_string: build_timestamp_string,
            load_run() {
                const { run_id } = this.$route.params;
                integrate_event_into_system({
                    system_path: ['runs', 'get_run_by_id', run_id],
                }).then(response => {
                    switch (response.status) {
                        case 200:
                            this.set_run(response.body);
                            break;
                        case 401:
                            respond_to_requires_authentication();
                            break;
                        default:
                            console.error(response);
                    }
                }).catch(console.log);
            },
            set_run(run) {
                this.run = run;
                get_gpx_string_for_trail(this.run)
                .then(gpx_string => {
                    const gps_track = convert_gpx_string_into_gps_track(gpx_string);
                    this.gps_track = gps_track;
                }).catch(error => this.error = error);
            },
        },
    }
</script>

<style scoped>
    table {
        text-align: left;
    }
</style>

