<template>
    <g
        v-bind:transform='transformation'
        v-on:click='handle_click'
    >
        <rect
            stroke='white'
            stroke-width='1'
            v-bind:width='dimensions.width'
            v-bind:height='dimensions.height'
        />
        <text
            fill='white'
            v-bind:x='dimensions.width / 2'
            v-bind:y='dimensions.height / 2 + 2'
            dominant-baseline='middle'
            text-anchor='middle'
            font-family='sans-serif'
            font-size='1.5em'
            font-weight='100'
        >{{ duration_text }}</text>
    </g>
</template>

<script>
    export default {
        name: 'TemperatureRangeIndicator',
        props: ['dimensions', 'duration'],
        computed: {
            duration_text() {
                if (this.is_day()) {
                    return 'DAY';
                }
                return 'WEEK';
            },
            transformation() {
                const {x, y} = this.dimensions;
                return `translate(${x}, ${y})`;
            },
        },
        methods: {
            is_day() {
                return this.duration === 'day';
            },
            handle_click() {
                this.$emit('toggle-duration');
            },
        },
    };
</script>

