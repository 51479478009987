<template>
    <div>
        <div v-bind:style='{ width: window_width }'>
            <img src='../assets/mark.jpg' />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        data() {
            return {
                window_width: window.innerWidth,
            };
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.on_resize);
            })
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.on_resize);
        },
        methods: {
            on_resize() {
                this.window_width = window.innerWidth;
            },
        },
    }
</script>

<style scoped>
    img {
        width: 100%;
    }
</style>

