import VueRouter from 'vue-router'

import Home from './components/Home.vue'

import Authenticate from './components/auth/Authenticate.vue';
import Login from './components/auth/Login.vue';
import Logout from './components/auth/Logout.vue';
import ClearAuthenticationToken from './components/auth/ClearAuthenticationToken.vue';
import ViewAuthStatus from './components/auth/ViewAuthStatus.vue';

import CreateDocument from './components/organizer/CreateDocument.vue';
import GenerateID from './components/organizer/GenerateID.vue';
import ViewRun from './components/runs/ViewRun.vue';
import ListRuns from './components/runs/ListRuns.vue';
import Planner from './components/planner/Planner.vue';
import WeatherGraph from './components/weather/WeatherGraph.vue';
import explore_spruce_hill from './components/memories/explore_spruce_hill.vue';
import wish_matt_happy_birthday from './components/letters/wish_matt_happy_birthday.vue';

const routes = [
    { path: '/', component: Home }, 

    { path: '/login', component: Login }, 
    { path: '/logout', component: Logout }, 
    { path: '/auth/login', component: Login }, 
    { path: '/auth/logout', component: Logout }, 
    { path: '/auth/authenticate', component: Authenticate },
    { path: '/auth/clear_authentication_token', component: ClearAuthenticationToken },
    { path: '/auth/view_status', component: ViewAuthStatus },

    { path: '/organizer/create_document', component: CreateDocument },
    { path: '/organizer/generate_id', component: GenerateID },
    { path: '/planner', component: Planner },

    { path: '/runs', component: ListRuns },
    { path: '/runs/:run_id', component: ViewRun },
    { path: '/weather', component: WeatherGraph }, 
    { path: '/weather/:place_name/:coordinates?', component: WeatherGraph }, 

    { path: '/memories/spruce-hill', component: explore_spruce_hill },

    { path: '/letters/2023.07.17', component: wish_matt_happy_birthday },

    { path: '/*', component: Home }, 
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;

