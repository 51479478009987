<template>
    <g
        v-bind:transform='transformation'
    >
        <rect
            stroke='white'
            stroke-width='1'
            v-bind:width='dimensions.width'
            v-bind:height='dimensions.height'
        />
        <g>
            <rect
                pointer-events='all'
                v-for='(range, index) in temperature_ranges'
                v-on:click='$emit("select_range", index)'
                v-bind:key='range.center'
                v-bind:width='dimensions.width'
                v-bind:height='dimensions.height / temperature_ranges.length'
                v-bind:y='index * (dimensions.height / temperature_ranges.length)'
                v-bind:fill='range.is_active ? styles.temperature_ranges.active.fill : styles.temperature_ranges.inactive.fill'
                v-bind:stroke='styles.temperature_ranges.stroke.color'
                v-bind:stroke-width='styles.temperature_ranges.stroke.width'
            />
        </g>
    </g>
</template>

<script>
    export default {
        name: 'TemperatureRangeIndicator',
        props: ['dimensions', 'temperature_ranges'],
        data() {
            return {
                mode: 'auto',
                styles: {
                    temperature_ranges: {
                        stroke: {
                            color: 'white',
                            width: 1,
                        },
                        active: {
                            fill: 'grey',
                        },
                        inactive: {
                            fill: 'none',
                        },
                    },
                },
            };
        },
        computed: {
            transformation() {
                const {x, y} = this.dimensions;
                return `translate(${x}, ${y})`;
            },
        },
    }
</script>

<style scoped>
</style>

