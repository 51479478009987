<template>
    <path
        v-bind:d='path_definition(points)'
        v-bind:stroke='stroke'
        stroke-width='2'
        v-bind:fill='graph_fill'
    />
</template>

<script>
    import { scaleLinear } from 'd3-scale';
    import { area, line } from 'd3-shape';

    export default {
        name: 'PlotPoints',
        props: [
            'type',
            'stroke',
            'fill',
            'points',
            'x_domain',
            'x_range',
            'y_domain',
            'y_range',
            'y0',
            'y1',
        ],
        computed: {
            path_definition() {
                if (this.type === 'line') {
                    return this.build_line_definition();
                } else if (this.type === 'area') {
                    return this.build_area_definition();
                }
                throw new Error(`Plot Points | Type Not Supported | ${this.type}`);
            },
            x_scale() {
                return scaleLinear()
                    .domain(this.x_domain)
                    .range(this.x_range);
            },
            y_scale() {
                return scaleLinear()
                    .domain(this.y_domain)
                    .range(this.y_range.slice().reverse());
            },
            graph_fill() {
                if (this.type === 'area') {
                    return this.fill;
                } else {
                    return 'none';
                }
            },
            graph_y0() {
                if (typeof this.y0 === 'undefined') {
                    return point => this.y_scale(point.y)
                }
                return this.y0;
            },
            graph_y1() {
                if (typeof this.y1 === 'undefined') {
                    return point => this.y_scale(point.y)
                }
                return this.y1;
            },
        },
        methods: {
            build_line_definition() {
                return line()
                    .x(point => this.x_scale(point.x))
                    .y(point => this.y_scale(point.y));
            },
            build_area_definition() {
                return area()
                    .x(point => this.x_scale(point.x))
                    .y0(this.graph_y0)
                    .y1(this.graph_y1);
            },
        },
    }
</script>

<style scoped>
</style>


