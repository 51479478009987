export default function filter_forecast_by_time_range({ forecast, time_range }) {
    const filtered_forecast = {};
    Object.keys(forecast).forEach(attribute => {
        filtered_forecast[attribute] = filter_predictions_by_time_range({
            predictions: forecast[attribute],
            time_range,
        });
    });
    return filtered_forecast;
}

function filter_predictions_by_time_range({ predictions, time_range }) {
    return predictions.filter(prediction => {
        const starts_after = prediction.start_timestamp >= time_range.start_timestamp;
        const ends_before  = prediction.start_timestamp <= time_range.end_timestamp;
        return starts_after && ends_before;
    });
}

