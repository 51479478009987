export default function build_x_gridlines({ predictions, width }) {
    if (should_show_gridlines_for_hours(predictions)) {
        return build_x_gridlines_for_hours({ predictions, width });
    }
    if (should_show_gridlines_for_days(predictions)) {
        return build_x_gridlines_for_days({ predictions });
    }
    return [];
}

function should_show_gridlines_for_hours(predictions) {
    const maximum_hours_to_display = 36;
    const number_of_hours = predictions.length;

    if (number_of_hours > maximum_hours_to_display) {
        return false;
    } else {
        return true;
    }
}

function should_show_gridlines_for_days(predictions) {
    return !should_show_gridlines_for_hours(predictions);
}

function build_x_gridlines_for_hours({ predictions, width }) {
    return predictions 
    .slice()
    .map((prediction, index) => build_hour_gridline_from_prediction({ prediction, index, width }));
}

function build_hour_gridline_from_prediction({ prediction, index, width }) {
    const time_string = build_time_string_from_prediction(prediction);
    const is_displayed = should_show_label_for_gridline({ index, width });

    return {
        text: is_displayed ? time_string : '',
        value: prediction.start_timestamp,
    };
}

function build_time_string_from_prediction(prediction) {
    const date = new Date(prediction.start_timestamp);
    const hours = date.getHours();

    if (hours === 0) {
        return '12am';
    } else if (hours < 12) {
        return `${hours}am`;
    } else if (hours === 12) {
        return '12pm';
    } else if (hours > 12) {
        return `${hours - 12}pm`;
    }
}

function should_show_label_for_gridline({ index, width }) {
    const label_spacing = determine_label_spacing(width);
    return (index + 1) % label_spacing === 0;
}

function determine_label_spacing(width) {
    if (width < 600) {
        return 6;
    }
    if (width < 800) {
        return 3;
    }
    if (width < 1200) {
        return 2;
    }
    return 1;
}

function build_x_gridlines_for_days({ predictions }) {
    return predictions
    .slice()
    .filter(is_prediction_at_midnight_or_noon)
    .map(build_day_gridline_from_prediction);
}

function is_prediction_at_midnight_or_noon(prediction) {
    const date = new Date(prediction.start_timestamp);
    const hours = date.getHours();
    return hours === 0 || hours === 12;
}

function build_day_gridline_from_prediction(prediction) { 
    const is_prediction_at_midnight = new Date(prediction.start_timestamp).getHours() === 0;

    return {
        text: is_prediction_at_midnight ? build_day_string_from_prediction(prediction) : '',
        value: prediction.start_timestamp,
    };
}

function build_day_string_from_prediction(prediction) {
    const date = new Date(prediction.start_timestamp);
    const day = date.getDay();
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return weekdays[day];
}

