import Vue from 'vue'
import VueRouter from 'vue-router'

import MarkKimmermanDotCom from './MarkKimmermanDotCom.vue'
import router from './router';

import integrate_event_into_system from 'src/tools/integrate_event_into_system.js';
import resources from 'src/tools/resources.js';

window.tools = {
    integrate_event_into_system,
    resources,
};

Vue.use(VueRouter);
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(MarkKimmermanDotCom),
}).$mount('#app')

