<template>
    <p>{{ id }}</p>
</template>

<script>
    import generate_unique_id from 'src/tools/generate_unique_id.js';

    export default {
        name: 'GenerateID',
        data() {
            return {
                id: null,
            };
        },
        mounted() {
            this.id = generate_unique_id();
        },
    }
</script>

<style scoped>
</style>

