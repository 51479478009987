<template>
    <div class='container'>
        <svg v-bind:width='dimensions.width' v-bind:height='dimensions.height'>
            <Background v-bind:dimensions='dimensions' />
            <TemperatureLineGraph
                v-if='forecast'
                v-bind:dimensions='component_dimensions.temperature_graph'
                v-bind:forecast='graphable_forecast'
                v-bind:time_range='graph_time_range'
                v-bind:temperature_ranges='temperature_ranges'
            />
            <ConditionsGraph
                v-if='forecast'
                v-bind:dimensions='component_dimensions.conditions_graph'
                v-bind:forecast='graphable_forecast'
                v-bind:time_range='graph_time_range'
            />
            <TemperatureRangeIndicator
                v-if='forecast'
                v-bind:dimensions='component_dimensions.temperature_range_indicator'
                v-bind:temperature_ranges='temperature_ranges'
                v-on:select_range='select_range'
            />
            <DurationToggle
                v-if='forecast'
                v-bind:dimensions='component_dimensions.duration_toggle'
                v-bind:duration='duration'
                v-on:toggle-duration='toggle_duration'
            />
        </svg>
    </div>
</template>

<script>
    import Background from './Background.vue';
    import DurationToggle from './DurationToggle.vue';
    import ConditionsGraph from './ConditionsGraph.vue';
    import TemperatureLineGraph from './TemperatureLineGraph.vue';
    import TemperatureRangeIndicator from './TemperatureRangeIndicator.vue';
    import integrate_event_into_system from 'src/tools/integrate_event_into_system.js';

    import {
        get_first_and_last_active_indexes_from_temperature_ranges,
        get_overlapping_ranges,
        respond_to_range_selection,
        temperature_ranges as reference_ranges,
    } from './tools/temperature_ranges.js';
    import build_component_dimensions from './tools/build_component_dimensions.js';
    import get_forecast_for_place from './tools/get_forecast_for_place.js';
    import filter_forecast_by_time_range from './tools/filter_forecast_by_time_range.js';

    const minutes_between_updates = 10;
    const milliseconds_between_updates = minutes_between_updates * 60 * 1000;

    export default {
        name: 'Weather',
        components: {
            Background,
            DurationToggle,
            TemperatureLineGraph,
            ConditionsGraph,
            TemperatureRangeIndicator,
        },
        data() {
            return {
                dimensions: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                },
                duration: 'day',
                forecast: null,
                graph_time_range: {
                    start_timestamp: 0,
                    end_timestamp: 0,
                },
                temperature_ranges: reference_ranges,
                temperature_range_selection_mode: 'auto',
                place: null,
            };
        },
        computed: {
            component_dimensions() {
                return build_component_dimensions(this.dimensions);
            },
            graphable_forecast() {
                return filter_forecast_by_time_range({
                    forecast: this.forecast,
                    time_range: this.graph_time_range,
                });
            },
            number_of_hours_to_plot() {
                if (this.duration === 'day') {
                    return 24;
                } else {
                    return 24 * 7;
                }
            },
        },
        mounted() {
            document.title = 'Weather';
            const { place_name, coordinates } = this.$route.params;
            if (coordinates) {
                const latitude = parseFloat(coordinates.split(',')[0]);
                const longitude = parseFloat(coordinates.split(',')[1]);
                this.populate_place_by_coordinates({ latitude, longitude });
            } else if (place_name) {
                this.populate_place_by_name(place_name);
            } else {
                this.populate_place_by_request_location();
            }
            this.$nextTick(() => {
                window.addEventListener('resize', this.on_resize);
                window.addEventListener('keypress', this.on_keypress);
            })
        },
        methods: {
            populate_place_by_coordinates(coordinates) {
                integrate_event_into_system({
                    system_path: ['weather', 'get_place_by_coordinates'],
                    input: coordinates,
                }).then(this.handle_place_response).catch(console.log);
            },
            populate_place_by_name(place_name) {
                integrate_event_into_system({
                    system_path: ['weather', 'get_place_by_nickname', place_name],
                }).then(this.handle_place_response).catch(console.log);
            },
            populate_place_by_request_location() {
                integrate_event_into_system({
                    system_path: ['weather', 'get_place_by_request_location'],
                }).then(this.handle_place_response).catch(console.log);
            },
            handle_place_response(response) {
                if (response.status === 200) {
                    this.set_place(response.body);
                    this.initialize_periodic_forecast_updates();
                } else {
                    console.log(response);
                }
            },
            set_place(place) {
                this.place = place;
                document.title = `Weather | ${this.place.name}`;
            },
            initialize_periodic_forecast_updates() {
                this.update_forecast();
                if (!this.interval) {
                    this.interval = setInterval(
                        this.update_forecast,
                        milliseconds_between_updates
                    );
                }
            },
            update_forecast() {
                const place = this.place;
                this.update_graph_time_range();
                get_forecast_for_place(place)
                .then(forecast => this.forecast = forecast)
                .then(this.activate_nearby_ranges);
            },
            update_graph_time_range() {
                const current_hour = new Date().setMinutes(0, 0, 0);
                this.graph_time_range = {
                    start_timestamp: current_hour,
                    end_timestamp: current_hour + (this.number_of_hours_to_plot * 1000 * 60 * 60),
                };
            },
            on_resize() {
                this.dimensions = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                };
            },
            toggle_duration() {
                if (this.duration === 'day') {
                    this.set_duration('week');
                } else {
                    this.set_duration('day');
                }
            },
            set_duration(duration) {
                this.duration = duration;
                this.update_graph_time_range();
                this.activate_nearby_ranges();
            },
            activate_nearby_ranges() {
                if (!this.forecast) {
                    return;
                }
                if (this.temperature_range_selection_mode === 'manual') {
                    return;
                }
                const temperatures = this.graphable_forecast.temperature_in_fahrenheit.map(
                    prediction => prediction.temperature_in_fahrenheit
                );
                const overlapping_ranges = get_overlapping_ranges({ temperatures });

                for (let index=0; index<reference_ranges.length; index++) {
                    const reference_range = reference_ranges[index];
                    const is_active = overlapping_ranges.map(range => range.center).includes(reference_range.center);
                    this.$set(this.temperature_ranges[index], 'is_active', is_active);
                }
            },
            select_range(selected_range_index) {
                this.temperature_range_selection_mode = 'manual';

                const range_activations = respond_to_range_selection({
                    temperature_ranges: this.temperature_ranges,
                    selected_range_index
                });

                for (let index=0; index<range_activations.length; index++) {
                    const is_range_active = range_activations[index];
                    this.$set(this.temperature_ranges[index], 'is_active', is_range_active);
                }
                
                const are_no_ranges_set = !this.temperature_ranges.map(range => range.is_active).includes(true);
                if (are_no_ranges_set) {
                    this.temperature_range_selection_mode = 'auto';
                    this.activate_nearby_ranges();
                } else {
                    this.fill_in_temperature_ranges();
                }
            },
            fill_in_temperature_ranges() {
                const { first_active_index, last_active_index } = get_first_and_last_active_indexes_from_temperature_ranges({
                    temperature_ranges: this.temperature_ranges,
                });

                for (let index = first_active_index + 1; index < last_active_index; index++) {
                    this.$set(this.temperature_ranges[index], 'is_active', true);
                }
            },
            on_keypress(event) {
                const { key } = event;
                if (key === 'w') {
                    this.set_duration('week');
                } else if (key === 'd') {
                    this.set_duration('day');
                } else if (key === 't') {
                    this.toggle_duration();
                } else if (['1', '2', '3', '4', '5'].includes(key)) {
                    const index = parseInt(key) - 1;
                    this.select_range(index);
                }
            },
        },
        beforeDestroy() {
            clearInterval(this.interval);
            window.removeEventListener('resize', this.on_resize);
            window.removeEventListener('keypress', this.on_keypress);
        },
    }
</script>

<style>
    .container {
        margin: 0;
        padding: 0;
        overflow: hidden;
        overflow-y: hidden;
    }
</style>

