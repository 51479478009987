<template>
    <g
        v-bind:transform='transformation'
    >
        <rect
            stroke='white'
            stroke-width='1'
            v-bind:width='dimensions.width'
            v-bind:height='dimensions.height'
        />
        <graph_x_axis
            v-bind:dimensions='dimensions'
            v-bind:x_domain='x_domain'
            v-bind:x_range='x_range'
            v-bind:gridlines='x_gridlines'
        />
        <graph_y_axis
            v-bind:dimensions='dimensions'
            v-bind:y_domain='y_domain'
            v-bind:y_range='y_range'
            v-bind:gridlines='y_gridlines'
            v-bind:maximum_label_width='maximum_y_axis_label_width'
        />
        <plot_points
            type='line'
            stroke='cyan'
            v-bind:x_domain='x_domain'
            v-bind:x_range='x_range'
            v-bind:y_domain='y_domain'
            v-bind:y_range='y_range'
            v-bind:points='points'
        />
    </g>
</template>

<script>
    import plot_points from '../graphing/plot_points.vue';
    import graph_x_axis from '../graphing/graph_x_axis.vue';
    import graph_y_axis from '../graphing/graph_y_axis.vue';

    import build_x_gridlines from './tools/build_x_gridlines.js';
    import { merge_temperature_ranges } from './tools/temperature_ranges.js';

    export default {
        name: 'TemperatureLineGraph',
        components: {
            plot_points,
            graph_x_axis,
            graph_y_axis,
        },
        props: ['dimensions', 'forecast', 'time_range', 'temperature_ranges'],
        computed: {
            points() {
                return this.forecast.temperature_in_fahrenheit.map(
                    prediction => ({
                        x: prediction.start_timestamp,
                        y: prediction.temperature_in_fahrenheit,
                    })
                );
            },
            x_domain() {
                return [
                    this.time_range.start_timestamp,
                    this.time_range.end_timestamp,
                ];
            },
            x_range() {
                return [0, this.dimensions.width];
            },
            y_domain() {
                return [
                    this.temperature_range.low,
                    this.temperature_range.high,
                ];
            },
            y_range() {
                return [0, this.dimensions.height];
            },
            x_gridlines() {
                return build_x_gridlines({
                    predictions: this.forecast.temperature_in_fahrenheit,
                    width: this.dimensions.width,
                });
            },
            y_gridlines() {
                const gridlines = [];
                for (let y=this.temperature_range.high; y>=this.temperature_range.low; y-=10) {
                    gridlines.push({
                        text: `${y}ºF`,
                        value: y,
                    });
                }
                return gridlines;
            },
            maximum_y_axis_label_width() {
                return this.dimensions.x * 0.75;
            },
            temperature_range() {
                const active_ranges = this.temperature_ranges.filter(range => range.is_active);
                return merge_temperature_ranges(active_ranges);
            },
            temperatures() {
                return this.forecast.temperature_in_fahrenheit.map(
                    prediction => prediction.temperature_in_fahrenheit
                );
            },
            transformation() {
                const {x, y} = this.dimensions;
                return `translate(${x}, ${y})`;
            },
        },
    }
</script>

<style scoped>
</style>

