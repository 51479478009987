export default function build_component_dimensions(dimensions) {
    const is_graph_under_components = should_graph_be_under_components(dimensions);

    if (is_graph_under_components) {
        return build_component_dimensions_with_graph_on_top(dimensions);
    } else {
        return build_component_dimensions_with_graph_on_right(dimensions);
    }
}

function should_graph_be_under_components(dimensions) {
    const width_by_height_ratio = dimensions.width / dimensions.height;
    const threshold_ratio = 1.4
    return width_by_height_ratio < threshold_ratio;
}

function build_component_dimensions_with_graph_on_top(dimensions) {
    return {
        temperature_graph: {
            x: dimensions.width * 0.10,
            width: dimensions.width * 0.85,
            y: dimensions.height * 0.05,
            height: dimensions.height * 0.60,
        },
        conditions_graph: {
            x: dimensions.width * 0.10,
            width: dimensions.width * 0.85,
            y: dimensions.height * 0.70,
            height: dimensions.height * 0.08,
        },
        temperature_range_indicator: {
            x: dimensions.width * 0.10,
            width: dimensions.width * 0.30,
            y: dimensions.height * 0.82,
            height: dimensions.height * 0.15,
        },
        duration_toggle: {
            x: dimensions.width * 0.60,
            width: dimensions.width * 0.20,
            y: dimensions.height * 0.87,
            height: dimensions.height * 0.05,
        },
    };
}

function build_component_dimensions_with_graph_on_right(dimensions) {
    return {
        temperature_graph: {
            x: dimensions.width * 0.2,
            width: dimensions.width * 0.75,
            y: dimensions.height * 0.05,
            height: dimensions.height * 0.80,
        },
        conditions_graph: {
            x: dimensions.width * 0.2,
            width: dimensions.width * 0.75,
            y: dimensions.height * 0.90,
            height: dimensions.height * 0.08,
        },
        temperature_range_indicator: {
            x: dimensions.width * 0.05,
            width: dimensions.width * 0.10,
            y: dimensions.height * 0.20,
            height: dimensions.height * 0.40,
        },
        duration_toggle: {
            x: dimensions.width * 0.05,
            width: dimensions.width * 0.10,
            y: dimensions.height * 0.70,
            height: dimensions.height * 0.05,
        },
    };
}

