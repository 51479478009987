export default function get_forecast_for_place(place) {
    return fetch(
        place.url,
    )
    .then(body => body.json())
    .then(build_hourly_forecast_from_weather_api_response);
}

function build_hourly_forecast_from_weather_api_response(weather_api_response) {
    return {
        temperature_in_fahrenheit: build_temperature_forecast(weather_api_response),
        probability_of_precipitation: build_precipitation_forecast(weather_api_response),
        sky_cover_percentage: build_sky_cover_forecast(weather_api_response),
    };
}

function build_temperature_forecast(weather_api_response) {
    const periods = transform_property_into_list_of_start_timestamps_and_values({
        property: weather_api_response.properties.temperature,
        attribute_name: 'temperature_in_fahrenheit',
        transform_value: value => value * 1.8 + 32,
    });
    return filter_out_past_hours(periods);
}

function build_precipitation_forecast(weather_api_response) {
    const periods = transform_property_into_list_of_start_timestamps_and_values({
        property: weather_api_response.properties.probabilityOfPrecipitation,
        attribute_name: 'probability_of_precipitation',
    });
    return filter_out_past_hours(periods);
}

function build_sky_cover_forecast(weather_api_response) {
    const periods = transform_property_into_list_of_start_timestamps_and_values({
        property: weather_api_response.properties.skyCover,
        attribute_name: 'sky_cover_percentage',
    });
    return filter_out_past_hours(periods);
}

function transform_property_into_list_of_start_timestamps_and_values({
    property,
    attribute_name,
    transform_value = (value) => value,
}) {
    return expand_time_intervals(property.values).map(
        interval => ({
            start_timestamp: interval.start_timestamp,
            [attribute_name]: transform_value(interval.value),
        }),
    );
}

function expand_time_intervals(time_intervals) {
    return time_intervals.reduce(
        (expansion, interval) => {
            const value = interval.value;
            const { start_timestamp, duration_in_hours } = parse_time_range(interval.validTime);

            const hourly_datapoints = [];
            for (let hour=0; hour<duration_in_hours; hour++) {
                hourly_datapoints.push({
                    start_timestamp: start_timestamp + (hour * 1000 * 60 * 60),
                    value,
                });
            }

            return expansion.concat(hourly_datapoints);
        },
        []
    );
}

function parse_time_range(time_range) {
    const [timestamp, duration] = time_range.split('/');
    const start_timestamp = new Date(timestamp).getTime();
    const duration_in_hours = parseInt(duration.replace(/\D/g, ''));
    return { start_timestamp, duration_in_hours };
}

function filter_out_past_hours(periods) {
    const one_hour_in_milliseconds = 60 * 60 * 1000;
    return periods.filter(
        ({start_timestamp}) => start_timestamp > Date.now() - one_hour_in_milliseconds
    );
}

