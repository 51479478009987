<template>
    <div class='container'>
        <svg
            v-bind:width='edge_length'
            v-bind:height='edge_length'
        >
            <g>
                <circle
                    v-bind:cx='edge_length / 2'
                    v-bind:cy='edge_length / 2'
                    r='25'
                    fill='white'
                />
                <circle
                    v-bind:cx='cx'
                    v-bind:cy='cy'
                    r='10'
                    fill='green'
                />
            </g>
        </svg>
        <div class='letter'>
            <p>Matt — </p>
            <p>The best part of you spending time in Turkey is that your birthday is heavily extended (at least from my point of view): it starts at 12:00am Turkey time and goes all the way until 11:59pm ET! And of course we'll celebrate when you get back, further extending your special day.</p>
            <p>As I write this I feel so proud to be your friend. You're such an incredible person and being in your presence feels wonderful — you always make people feel seen and heard, and you approach with true curiosity and interest. You're such a pleasure to spend time with and be around!</p>
            <p>I admire your mind and love seeing it in action. You dive into problems completely and approach them from all different angles. It's a treat to see you bring your past experiences and learnings to bear on new ideas. I'm really looking forward to doing play projects together and to feel our minds resonate.</p>
            <p>Your music journey is epic — your dedication and commitment are equal parts impressive and inspiring. You've already grown <u>so</u> much, and it feels like your journey has just begun! (Save for the decades of foundation that you're building upon.) I'm so lucky to be able to experience your journey and can't wait to see all the places that this will bring you.</p>
            <p>Here are some things I'm excited to do together over the next couple months:</p>
            <ul>
                <li>Hike together in Lincoln (and you using your birthday gift to see far off things)</li>
                <li>Attend MIT Swap Meet</li>
                <li>Play Generals over Radio</li>
                <li>Play Halo</li>
            </ul>
            <p>Happy Birthday! Talk to you soon!</p>
            <p class='no-bottom-margin right-align'>Your brother,</p>
            <p class='no-top-margin right-align'>Mark</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'wish_matt_happy_birthday',
        data() {
            return {
                steps: 400,
                current_step: 1,
                radius: 75,
                edge_length: 200,
            };
        },
        mounted() {
            this.timer = setInterval(
                () => this.animate(),
                15
            );
        },
        methods: {
            animate() {
                this.current_step++;
                if (this.current_step === this.steps) {
                    this.current_step = 1;
                }
            },
        },
        computed: {
            cx() {
                const percent_complete = this.current_step / this.steps;
                const x_offset = this.radius * Math.sin(2 * Math.PI * percent_complete);
                return this.edge_length / 2 + x_offset;
            },
            cy() {
                const percent_complete = this.current_step / this.steps;
                const y_offset = this.radius * Math.cos(2 * Math.PI * percent_complete);
                return this.edge_length / 2 - y_offset;
            },
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
    }
</script>

<style scoped>
    .container {
        background-color: #333;
        color: #eee;

        padding-top: 20px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .letter {
        max-width: 450px;
        padding: 16px;
        font-family: 'Verdana';
    }

    .letter p.right-align {
        text-align: right;
    }

    .letter p.no-bottom-margin {
        margin-bottom: 0;
    }

    .letter p.no-top-margin {
        margin-top: 0;
    }
</style>
