<template>
    <div>
        Logging in...
    </div>
</template>

<script>
    import {
        redirect_to_auth0_login,
        store_desired_url,
    } from './auth.js';

    export default {
        name: 'Login',
        mounted() {
            store_desired_url(document.referrer);
            redirect_to_auth0_login();
        },
    }
</script>

<style scoped>
</style>

