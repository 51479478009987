<template>
    <p v-if='error'><b>Error loading runs:</b> {{ error.message }}</p>
    <div v-else-if='is_loading === false'>
        <div class='table_surface'>
            <table>
                <thead>
                    <tr>
                        <th rowspan='2'>Timestamp</th>
                        <th rowspan='2'>Duration</th>
                        <th rowspan='2'>Speed</th>
                        <th colspan='2'>Kilometers</th>
                        <th colspan='2'>Miles</th>
                        <th rowspan='2'></th>
                    </tr>
                    <tr>
                        <!-- Timestamp -->
                        <!-- Duration -->
                        <!-- Speed -->
                        <th>Distance</th>
                        <th>Pace</th>
                        <th>Distance</th>
                        <th>Pace</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for='run in runs' v-bind:key='run.id'
                        v-bind:class='[run.id === active_run_id ? "active" : ""]'
                        v-on:click='set_active_run(run)'
                    >
                        <td>{{ build_timestamp_string(run.timestamp) }}</td>
                        <td>{{ run.display.duration }}</td>
                        <td>{{ run.display.speed }}</td>
                        <td>{{ run.display.distance_in_kilometers }}</td>
                        <td>{{ run.display.pace_per_kilometer }}</td>
                        <td>{{ run.display.distance_in_miles }}</td>
                        <td>{{ run.display.pace_per_mile }}</td>
                        <td>
                            <router-link v-bind:to='`/runs/${run.id}`'>View</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <GraphRuns
            v-bind:runs='runs'
            v-bind:active_run_id='active_run_id'
        />
    </div>
    <p v-else>Loading...</p>
</template>

<script>
    import GraphRuns from './GraphRuns.vue';

    import build_timestamp_string from 'src/tools/build_timestamp_string.js';
    import integrate_event_into_system from 'src/tools/integrate_event_into_system.js';
    import { respond_to_requires_authentication } from 'src/components/auth/auth.js';

    export default {
        name: 'ListRuns',
        components: {
            GraphRuns,
        },
        data() {
            return {
                error: null,
                is_loading: true,
                runs: [],
                active_run_id: null,
            }
        },
        mounted() {
            window.create_run_from_gpx_file_url = this.create_run_from_gpx_file_url;
            window.refresh_run_by_id = this.refresh_run_by_id;
            window.load_runs = this.load_runs;
            window.delete_run_by_id = this.delete_run_by_id;
            this.load_runs();
        },
        methods: {
            load_runs() {
                integrate_event_into_system({
                    system_path: ['runs', 'get_runs'],
                }).then(response => {
                    switch (response.status) {
                        case 200:
                            this.runs = response.body.sort(
                                (a, b) => b.timestamp - a.timestamp
                            );
                            window.runs = this.runs;
                            this.is_loading = false;
                            break;
                        case 401:
                            respond_to_requires_authentication();
                            break;
                        default:
                            this.is_loading = false;
                            console.error(response);
                    }
                }).catch(console.log);
            },
            create_run_from_gpx_file_url(gpx_file_url) {
                return integrate_event_into_system({
                    system_path: ['runs', 'create_run_from_gpx_file_url'],
                    input: { gpx_file_url },
                });
            },
            build_timestamp_string: build_timestamp_string,
            refresh_run_by_id(run_id) {
                return integrate_event_into_system({
                    system_path: ['runs', 'refresh_run_analysis', run_id],
                }).then(console.log);
            },
            delete_run_by_id(run_id) {
                return integrate_event_into_system({
                    system_path: ['runs', 'delete_run_by_id', run_id],
                }).then(console.log);
            },
            set_active_run(run) {
                if (this.active_run_id === run.id) {
                    this.active_run_id = null;
                } else {
                    this.active_run_id = run.id;
                }
            },
        },
    }
</script>

<style scoped>
    .table_surface {
        padding: 1em;
        overflow-x: scroll;
    }

    table {
        border-collapse: collapse;
        font-family: sans-serif;
        text-align: center;
    }

    table thead tr {
        background-color: rgb(217, 217, 217);
    }

    table th, table td {
        padding: 0.5em;
    }

    table tr, table th, table td {
        border: 1px solid black;
    }

    table tr.active {
        background-color: rgba(0, 255, 255, 0.15);
    }
</style>

