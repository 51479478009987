<template>
    <div ref='container'>
        <button v-on:click='download_svg'>Download SVG</button>
        <svg
            ref='svg'
            v-bind:width='dimensions.width'
            v-bind:height='dimensions.height'
        >
            <g ref='background'>
                <rect
                    fill='#f0e7d6'
                    v-bind:width='dimensions.width'
                    v-bind:height='dimensions.height'
                />
            </g>
            <g v-bind:transform='`translate(${x_scale(4)}, ${y_scale(4)})`'>
                <plot_text
                    fill='black'
                    v-bind:line_height='y_scale(6)'
                    v-bind:letter_spacing='y_scale(6 / 2.1)'
                    v-bind:text='title'
                />
            </g>
            <g>
                <line
                    v-for='(section, index) in section_dividers' :key='index'
                    v-bind:x1='x_scale(section.x1)'
                    v-bind:x2='x_scale(section.x2)'
                    v-bind:y1='y_scale(section.y1)'
                    v-bind:y2='y_scale(section.y2)'
                    stroke='black'
                    stroke-weight='1'
                />
            </g>
            <g ref='habits'>
                <Habits
                    v-bind:habits='habits'
                    v-bind:x='x_scale(configuration.habits.x)'
                    v-bind:y='y_scale(configuration.habits.y)'
                    v-bind:font_size='configuration.habits.font_size'
                    v-bind:line_height='configuration.habits.line_height'
                    v-bind:vertical_spacing='configuration.habits.vertical_spacing'
                    v-bind:width='dimensions.width * configuration.habits.width_percentage'
                    v-bind:height='dimensions.height * configuration.habits.height_percentage'
                />
            </g>
            <g
                v-if='have_initial_components_been_drawn'
                v-bind:transform='`translate(${x_scale(configuration.goals.x)}, ${goals_layout.y})`'
            >
                <plot_text
                    v-for='(goal, index) in goals' :key='index'
                    fill='black'
                    v-bind:line_height='y_scale(configuration.goals.line_height)'
                    v-bind:letter_spacing='y_scale(configuration.goals.line_height / 2.1)'
                    v-bind:y='y_scale(index * (configuration.goals.line_height + configuration.goals.vertical_spacing))'
                    v-bind:text='`- ${goal}`'
                />
            </g>
        </svg>
    </div>
</template>

<script>
    import { scaleLinear } from 'd3-scale';

    import Habits from './Habits.vue';
    import plot_text from 'src/components/graphing/text/plot_text.vue';
    import download_file from 'src/tools/download_file.js';

    const MUJI_NOTEBOOK_DIMENSIONS = {
        WIDTH: 88,
        HEIGHT: 125,
    };

    export default {
        name: 'View_Plan_as_SVG',
        components: {
            Habits,
            plot_text,
        },
        props: ['habits', 'goals', 'title'],
        data() {
            return {
                have_initial_components_been_drawn: false,
                dimensions: {
                    width: 200,
                    height: 400,
                },
                configuration: {
                    habits: {
                        x: 4,
                        y: 12,
                        font_size: 17,
                        line_height: 25,
                        vertical_spacing: 7,
                        width_percentage: 0.43,
                        height_percentage: 0.80,
                    },
                    goals: {
                        x: 4,
                        line_height: 3.5,
                        vertical_spacing: 0.0,
                        margin: {
                            y: 12,
                        },
                    },
                },
                section_dividers: [
                    { x1: 4, x2: 84, y1: 10, y2: 10 },
                    {
                        x1: MUJI_NOTEBOOK_DIMENSIONS.WIDTH / 2,
                        x2: MUJI_NOTEBOOK_DIMENSIONS.WIDTH / 2,
                        y1: 10, y2: 120,
                    },
                ],
            };
        },
        computed: {
            x_scale() {
                const domain = [0, MUJI_NOTEBOOK_DIMENSIONS.WIDTH];
                const range = [0, this.dimensions.width];
                return scaleLinear()
                    .domain(domain)
                    .range(range);
            },
            y_scale() {
                const domain = [0, MUJI_NOTEBOOK_DIMENSIONS.HEIGHT];
                const range = [0, this.dimensions.height];
                return scaleLinear()
                    .domain(domain)
                    .range(range);
            },
            goals_layout() {
                const habits_element = this.$refs.habits;
                if (!habits_element) {
                    return null;
                }
                const habits_bounding_box = habits_element.getBBox();
                return {
                    x: habits_bounding_box.x,
                    y: habits_bounding_box.y + habits_bounding_box.height
                       + this.y_scale(this.configuration.goals.margin.y),
                };
            },
        },
        methods: {
            download_svg() {
                this.remove_background();
                const svg_element = this.$refs.svg;
                const svg_definition = svg_element.outerHTML;

                download_file({
                    name: `Planner | ${this.title}.svg`,
                    body: svg_definition,
                });
            },
            remove_background() {
                const background_element = this.$refs.background;
                background_element.remove();
            },
        },
        mounted() {
            // Unimplemented | Limit Height to Prevent Overflow-y
            // Unimplemented | Dynamically Adjust Dimensions on Resize
            const container_width = this.$refs.container.clientWidth;
            const aspect_ratio = MUJI_NOTEBOOK_DIMENSIONS.WIDTH / MUJI_NOTEBOOK_DIMENSIONS.HEIGHT;
            this.dimensions = {
                width: container_width,
                height: container_width / aspect_ratio,
            };
            this.have_initial_components_been_drawn = true;
        },
    }
</script>

<style scoped>
    div {
        width: 100%;
    }
</style>

