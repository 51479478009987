import integrate_event_into_system from './integrate_event_into_system.js';

export default {
    create_resource,
    get_resource_by_key,
    get_resource_keys_by_prefix,
    delete_resource_by_key,
};

async function create_resource({ key, ...attributes }) {
    return integrate_event_into_system({
        system_path: ['resources', 'create'],
        input: { key, ...attributes }
    });
}

async function get_resource_by_key(key) {
    return integrate_event_into_system({
        system_path: ['resources', 'get_by_key'],
        input: key,
    });
}

async function get_resource_keys_by_prefix(key_prefix) {
    return integrate_event_into_system({
        system_path: ['resources', 'get_resource_keys_by_prefix'],
        input: key_prefix,
    });
}

async function delete_resource_by_key(key) {
    return integrate_event_into_system({
        system_path: ['resources', 'delete_resource_by_key'],
        input: key,
    });
}

