import { get_authentication_token } from 'src/components/auth/auth.js';

export default async function integrate_event_into_system({
    system_path, input = {},
}) {
    const url = build_url_from_system_path(system_path);
    const body = build_body_from_input(input);
    const headers = build_headers();
    return fetch(url, {
        method: 'POST',
        body,
        headers,
    })
    .then(response => {
        return response.json().then(body => {
            return {
                status: response.status,
                body,
            };
        });
    });
}

function build_url_from_system_path(system_path) {
    const base_url = build_base_url();
    const system_uri = system_path.join('/');
    return `${base_url}/${system_uri}`;
}

function build_base_url() {
    return process.env.VUE_APP_SERVER_URL;
}

function build_body_from_input(input) {
    if (!input) {
        return;
    }
    return JSON.stringify(input);
}

function build_headers() {
    const token = get_authentication_token();
    if (!token) {
        return;
    }

    return {
        Authorization: `Bearer ${token}`
    };
}

