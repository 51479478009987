<template>
    <div>
        <textarea
            v-model='plan_definition'
            rows='15'
        />
    </div>
</template>

<script>
    import parse_plan_from_definition from './parse_plan_from_definition.js';

    export default {
        name: 'Define_Plan',
        data() {
            return {
                plan_definition: '',
            };
        },
        watch: {
            plan_definition(new_definition) {
                const plan = parse_plan_from_definition(new_definition);
                this.$emit('update_plan', plan);
            },
        },
    }
</script>

<style scoped>
    div {
        padding: 1em;
    }

    textarea {
        width: 300px;
    }
</style>

