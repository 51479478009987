<template>
    <g
        v-bind:transform='`translate(${x}, ${y})`'
    >
        <g
            v-for='(habit, index) in habits' :key='index'
            v-bind:transform='`translate(0, ${index * (line_height + vertical_spacing)})`'
        >
            <plot_text
                fill='black'
                v-bind:line_height='line_height'
                v-bind:letter_spacing='line_height / 2.1'
                v-bind:text='habit.name'
                v-bind:y='line_height / 10'
            />
            <g>
                <rect
                    v-for='(box, index) in build_boxes_for_habit(habit)'
                    :key='index'
                    v-bind:x='box.x'
                    v-bind:y='box.y'
                    v-bind:width='box.width'
                    v-bind:height='box.height'
                    stroke='black'
                    stroke-width='1'
                    fill='none'
                />
            </g>
        </g>
    </g>
</template>

<script>
    import plot_text from 'src/components/graphing/text/plot_text.vue';

    export default {
        name: 'Habits',
        components: {
            plot_text,
        },
        props: [
            'dimensions',
            'habits',
            'font_size',
            'line_height',
            'vertical_spacing',
            'x',
            'y',
            'width',
            'height',
        ],
        methods: {
            build_boxes_for_habit(habit) {
                const { target_repetitions } = habit;
                const box_spacing = 5;
                const width_of_boxes = this.width / 2;
                const box_width = (width_of_boxes - (target_repetitions - 1) * box_spacing) / target_repetitions;
                const boxes = [];

                for (let i=0; i<target_repetitions; i++) {
                    const box = {
                        x: this.width - width_of_boxes + i * (box_spacing + box_width),
                        y: 0,
                        width: box_width,
                        height: this.line_height,
                    };
                    boxes.push(box);
                }

                return boxes;
            },
        },
    }
</script>

<style scoped>
</style>

