/*
title
    2022.02.07 through 2022.02.13
habits
    First Habit | 3
    Second Habit | 1
goals
    Run Test
    View Results
*/

export default function parse_plan_from_definition(definition) {
    const lines = definition.split('\n').filter(line => line !== '');
    const systems = build_systems_from_lines(lines);

    systems.habits = systems.habits || [];
    systems.habits = systems.habits.map(habit => {
        const [name, target_repetitions] = habit.split(' | ');
        return { name, target_repetitions: parseInt(target_repetitions) };
    });
    systems.goals = systems.goals || [];
    systems.title = systems.title && systems.title[0];

    console.log(systems);

    return systems;
}

function build_systems_from_lines(lines) {
    const least_indented_line_indicies = get_line_indicies_with_lowest_indentation(lines);
    const systems_and_components = organize_into_systems({
        system_indices: least_indented_line_indicies,
        lines,
    });
    return systems_and_components;
}

function get_line_indicies_with_lowest_indentation(lines) {
    const indentation_amounts = lines.map(get_number_of_prefixed_tabs);
    const minimum_indentation = Math.min.apply(null, indentation_amounts);
    const line_indicies_with_lowest_indentation = [];
    for (let index=0; index<indentation_amounts.length; index++) {
        if (indentation_amounts[index] === minimum_indentation) {
            line_indicies_with_lowest_indentation.push(index);
        }
    }
    return line_indicies_with_lowest_indentation;
}

function get_number_of_prefixed_tabs(line) {
    let number_of_prefixed_tabs = 0;
    let character_index = 0;
    line = line.replace('    ', '\t');
    while (line.charAt(character_index++) === '\t') {
        number_of_prefixed_tabs++;
    }
    return number_of_prefixed_tabs;
}

function organize_into_systems({ system_indices, lines }) {
    const systems = {};
    for (let index=0; index<system_indices.length; index++) {
        const system_index = system_indices[index];
        const start_index = system_index + 1;
        const end_index = (
            index === system_indices.length - 1
            ? lines.length
            : system_indices[index + 1]
        );

        const system_name = lines[system_index].trim();
        const components = lines.slice(start_index, end_index).map(line => line.trim());

        systems[system_name] = components;
    }
    return systems;
}

