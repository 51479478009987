<template>
    <svg
        v-bind:width='dimensions.width'
        v-bind:height='dimensions.height'
    >
        <rect
            v-bind:width='dimensions.width'
            v-bind:height='dimensions.height'
            fill='black'
        />
        <g v-bind:transform='transform_graph'>
            <graph_x_axis
                v-bind:dimensions='graph_dimensions'
                v-bind:x_domain='x_domain'
                v-bind:x_range='x_range'
                v-bind:gridlines='x_gridlines'
            />
            <graph_y_axis
                v-bind:dimensions='graph_dimensions'
                v-bind:y_domain='y_domain'
                v-bind:y_range='y_range'
                v-bind:gridlines='y_gridlines'
                v-bind:maximum_label_width='graph_dimensions.margins.left * 0.80'
            />
            <plot_points
                v-for='(run, index) in runs' v-bind:key='run.id'
                type='line'
                v-bind:stroke='build_stroke_for_run(run, index)'
                v-bind:x_domain='x_domain'
                v-bind:x_range='x_range'
                v-bind:y_domain='y_domain'
                v-bind:y_range='y_range'
                v-bind:points='build_points_for_run(run)'
            />
        </g>
    </svg>
</template>

<script>
    import plot_points from 'src/components/graphing/plot_points.vue';
    import graph_x_axis from 'src/components/graphing/graph_x_axis.vue';
    import graph_y_axis from 'src/components/graphing/graph_y_axis.vue';

    export default {
        name: 'GraphRuns',
        components: {
            plot_points,
            graph_x_axis,
            graph_y_axis,
        },
        props: ['runs', 'active_run_id'],
        data() {
            return {
                dimensions: {
                    width: window.innerWidth,
                    height: 350,
                },
            };
        },
        computed: {
            graph_dimensions() {
                const margins = {
                    left: 0.10,
                    top: 0.10,
                };
                return {
                    width: 0.85 * this.dimensions.width,
                    height: 0.80 * this.dimensions.height,
                    margins: {
                        left: margins.left * this.dimensions.width,
                        top: margins.top * this.dimensions.height,
                    },
                };
            },
            x_domain() { return [0, 5000]; },
            x_range()  { return [0, this.graph_dimensions.width]; },
            y_domain() { return [3.0, 5.0]; },
            y_range()  { return [0, this.graph_dimensions.height]; },
            x_gridlines() {
                return [
                    { text: '0km', value:    0 },
                    { text: '1km', value: 1000 },
                    { text: '2km', value: 2000 },
                    { text: '3km', value: 3000 },
                    { text: '4km', value: 4000 },
                    { text: '5km', value: 5000 },
                ];
            },
            y_gridlines() {
                return [
                    { text: '3.0 m/s', value: 3.0 },
                    { text: '3.5 m/s', value: 3.5 },
                    { text: '4.0 m/s', value: 4.0 },
                    { text: '4.5 m/s', value: 4.5 },
                    { text: '5.0 m/s', value: 5.0 },
                ];
            },
            transform_graph() {
                return `translate(
                    ${this.graph_dimensions.margins.left},
                    ${this.graph_dimensions.margins.top}
                )`;
            },
        },
        methods: {
            build_points_for_run(run) {
                return run.speed_over_distance.map(
                    point => ({
                        x: point.distance,
                        y: point.speed,
                    })
                );
            },
            build_stroke_for_run(run, index) {
                if (run.id === this.active_run_id) {
                    return 'cyan';
                }
                const opacity = (this.runs.length - index) / this.runs.length;
                return `rgba(255, 255, 255, ${opacity})`;
            },
            on_resize() {
                this.dimensions.width = window.innerWidth;
            },
        },
        mounted() {
            document.title = 'Runs';
            this.$nextTick(() => {
                window.addEventListener('resize', this.on_resize);
            })
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.on_resize);
        },
    }
</script>

<style scoped>
</style>

