export const temperature_ranges = [
    { high: 110, center:  90, low:  70 },
    { high:  90, center:  70, low:  50 },
    { high:  70, center:  50, low:  30 },
    { high:  50, center:  30, low:  10 },
    { high:  30, center:  10, low: -10 },
].map(range => ({ ...range, is_active: true }));

export function merge_temperature_ranges(ranges) {
    return {
        high: ranges[0].high,
        low:  ranges[ranges.length - 1].low,
    };
}

export function get_overlapping_ranges({ temperatures }) {
    const primary_range = get_primary_range({ temperatures });
    const higher_ranges = get_ranges_above_primary({ primary_range, temperatures });
    const lower_ranges  = get_ranges_below_primary({ primary_range, temperatures });

    return [...higher_ranges, primary_range, ...lower_ranges];
}

function get_primary_range({ temperatures }) {
    const average = temperatures.reduce((sum, value) => sum + value, 0) / temperatures.length;
    let min_difference = null;
    let range_index = 0;
    for (let index=0; index<temperature_ranges.length; index++) {
        const range = temperature_ranges[index];
        const difference_to_center = Math.abs(range.center - average);
        if (min_difference === null || difference_to_center < min_difference) {
            min_difference = difference_to_center;
            range_index = index;
        }
    }
    return temperature_ranges[range_index];
}

function get_ranges_above_primary({ primary_range, temperatures }) {
    const high = get_high({ temperatures });
    const range_index = get_range_index({ range: primary_range });
    if (high > primary_range.high) {
        if (range_index === 0) {
            return [];
        } else {
            const range = temperature_ranges[range_index - 1];
            const higher_ranges = get_ranges_above_primary({ primary_range: range, temperatures });
            return [...higher_ranges, range];
        }
    }
    return [];
}

function get_high({ temperatures }) {
    return Math.max.apply(null, temperatures);
}

function get_range_index({ range }) {
    const centers = temperature_ranges.map(r => r.center);
    return centers.findIndex(center => center === range.center);
}

function get_ranges_below_primary({ primary_range, temperatures }) {
    const low = get_low({ temperatures });
    const range_index = get_range_index({ range: primary_range });
    if (low < primary_range.low) {
        if (range_index === temperature_ranges.length - 1) {
            return [];
        } else {
            const range = temperature_ranges[range_index + 1];
            const lower_ranges = get_ranges_below_primary({ primary_range: range, temperatures });
            return [range, ...lower_ranges];
        }
    }
    return [];
}

function get_low({ temperatures }) {
    return Math.min.apply(null, temperatures);
}

export function get_first_and_last_active_indexes_from_temperature_ranges({ temperature_ranges }) {
    const range_activations = temperature_ranges.map(range => range.is_active);
    return get_first_and_last_active_indexes({ range_activations });
}

function get_first_and_last_active_indexes({ range_activations }) {
    const number_of_ranges = range_activations.length;
    const first_active_index = range_activations.findIndex(is_active => is_active);
    const last_active_index = number_of_ranges - 1 - range_activations.slice().reverse().findIndex(is_active => is_active);

    return { first_active_index, last_active_index };
}

export function respond_to_range_selection({ temperature_ranges, selected_range_index }) {
    const range_activations = temperature_ranges.map(range => range.is_active);
    const number_of_active_ranges = range_activations.filter(is_active => is_active).length;

    if (number_of_active_ranges < 3) {
        range_activations[selected_range_index] = !range_activations[selected_range_index];
        return range_activations;
    } else if (number_of_active_ranges === 3) {
        return respond_to_selection_with_3_active_ranges({ range_activations, selected_range_index });
    } else if (number_of_active_ranges === 4) {
        return respond_to_selection_with_4_active_ranges({ range_activations, selected_range_index });
    } else if (number_of_active_ranges === 5) {
        return respond_to_selection_with_5_active_ranges({ range_activations, selected_range_index });
    }
}

function respond_to_selection_with_3_active_ranges({ range_activations, selected_range_index }) {
    const { first_active_index, last_active_index } = get_first_and_last_active_indexes({ range_activations });
    if (selected_range_index === first_active_index + 1) {
        range_activations[first_active_index] = false;
        range_activations[last_active_index] = false;
        return range_activations;
    } else {
        range_activations[selected_range_index] = !range_activations[selected_range_index];
        return range_activations;
    }
}

function respond_to_selection_with_4_active_ranges({ range_activations, selected_range_index }) {
    const { first_active_index, last_active_index } = get_first_and_last_active_indexes({ range_activations });
    if (selected_range_index === first_active_index + 1) {
        range_activations[first_active_index] = false;
        range_activations[first_active_index + 1] = false;
        return range_activations;
    } else if (selected_range_index === last_active_index - 1) {
        range_activations[last_active_index] = false;
        range_activations[last_active_index - 1] = false;
        return range_activations;
    } else {
        range_activations[selected_range_index] = !range_activations[selected_range_index];
        return range_activations;
    }
}

function respond_to_selection_with_5_active_ranges({ range_activations, selected_range_index }) {
    if (selected_range_index === 0 || selected_range_index === 4) {
        range_activations[selected_range_index] = !range_activations[selected_range_index];
        return range_activations;
    } else if (selected_range_index === 1) {
        range_activations[0] = false;
        range_activations[1] = false;
        return range_activations;
    } else if (selected_range_index === 3) {
        range_activations[4] = false;
        range_activations[3] = false;
        return range_activations;
    } else if (selected_range_index === 2) {
        range_activations[0] = false;
        range_activations[4] = false;
        return range_activations;
    }
}

