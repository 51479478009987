<template>
    <div class='container'>

        <div class='header'>
            <h1 class='title'>Spruce Hill</h1>
            <ul class='menu'>
                <li><a href='#features'>Features</a></li>
                <li><a href='#details'>Details</a></li>
                <li><a href='#map'>Map</a></li>
                <li><a target='_blank' href='https://www.google.com/maps/dir/37+Pawnee+Road,+West+Yarmouth,+MA/Spruce+Hill+Conservation+Area,+BREW-102-0017,+%23000,+Brewster,+MA+02631/@41.776377,-70.0439291,17z/data=!4m14!4m13!1m5!1m1!1s0x89fb3a6f6c3fec3b:0xa4cf6d5460eca904!2m2!1d-70.2249039!2d41.6442707!1m5!1m1!1s0x89fb6b97b752c2d3:0xc128c408727e2361!2m2!1d-70.0417404!2d41.776377!3e0'>Directions</a></li>
            </ul>
        </div>

        <div class='description'>
            <p class='text'>{{ description.content }}</p>
        </div>

       <div class='details'>

            <div class='map'>
                <img src='/images/location-of-spruce-hill-in-cape-cod.jpg' />
            </div>

            <div class='features' id='features'>
                <h2 class='section-title'>Features</h2>
                <!-- https://stackoverflow.com/questions/14745297/how-to-display-an-unordered-list-in-two-columns -->
                <ul>
                    <li
                        v-for='(item, index) in features.items'
                        v-bind:key='index'
                    >{{ item }}</li>
                </ul>
            </div>

            <div class='specifications' id='details'>
                <h2 class='section-title'>Details</h2>
                <table>
                    <tbody>
                        <tr>
                            <th>Name:</th><td>Spruce Hill Conservation Area</td>
                        </tr>
                        <tr>
                            <th>Town:</th><td>Brewster (15.2 miles from Pawnee)</td>
                        </tr>
                        <tr>
                            <th>Trail Length:</th><td>0.8 miles round trip</td>
                        </tr>
                        <tr>
                            <th>Beach Faces:</th><td>NNW</td>
                        </tr>
                        <tr>
                            <th>Directions:</th><td>See Google Maps</td>
                        </tr>
                        <tr>
                            <th>Parking:</th><td>Area located off Route 6A</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class='map' id='map'>
                <img src='/images/map-of-spruce-hill.png' />
            </div>

            <div class='hikes'>

                <div class='hike'>
                    <h2 class='section-title'>Hike 1 - Scope it Out</h2>
                    <ul>
                        <li><strong>Time of Year:</strong> Winter</li>
                        <br />
                        <li><strong>Goal:</strong> Identify picnic spots</li>
                        <li><strong>Goal:</strong> Identify flora</li>
                        <li><strong>Goal:</strong> Have fun on a new trai</li>
                        <br />
                        <li><strong>Date:</strong> Any of the following Saturdays:
                            <ul>
                                <li>January 21st</li>
                                <li>January 28st</li>
                                <li>Any in February</li>
                                <li>Any in March</li>
                            </ul>
                        </li>
                        <br />
                        <li><strong>Photos</strong>
                            <ul>
                                <li>Will be uploaded after the hike!</li>
                            </ul>
                        </li>
                        <br />
                        <li><strong>Walking Path</strong>
                            <ul>
                                <li>Will be uploaded after the hike!</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class='hike'>
                    <h2 class='section-title'>Hike 2 - Picnic</h2>
                    <ul>
                        <li><strong>Time of Year:</strong> Spring</li>
                        <br />
                        <li><strong>Goal:</strong> Have a picnic on the beach</li>
                        <li><strong>Goal:</strong> Enjoy warm(er) weather</li>
                        <li><strong>Goal:</strong> Identify changes in landscape</li>
                        <li><strong>Goal:</strong> Identify changes in flora</li>
                        <br />
                        <li><strong>Date:</strong>
                            <ul>
                                <li><strong>Proposal:</strong> Saturday, April 1</li>
                            </ul>
                        </li>
                        <br />
                        <li><strong>Photos</strong>
                            <ul>
                                <li>Will be uploaded after the hike!</li>
                            </ul>
                        </li>
                        <br />
                        <li><strong>Walking Path</strong>
                            <ul>
                                <li>Will be uploaded after the hike!</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                
            </div>

       </div>

    </div>
</template>

<script>
    export default {
        name: 'explore_spruce_hill',
        data() {
            return {
                window: {
                    width:  window.innerWidth,
                    height: window.innerHeight,
                },
                header: {
                    menu: {
                        items: [
                            { name: 'Features' },
                            { name: 'Details' },
                            { name: 'Map' },
                            { name: 'Directions', href: 'https://www.google.com/maps/dir/37+Pawnee+Road,+West+Yarmouth,+MA/Spruce+Hill+Conservation+Area,+BREW-102-0017,+%23000,+Brewster,+MA+02631/@41.776377,-70.0439291,17z/data=!4m14!4m13!1m5!1m1!1s0x89fb3a6f6c3fec3b:0xa4cf6d5460eca904!2m2!1d-70.2249039!2d41.6442707!1m5!1m1!1s0x89fb6b97b752c2d3:0xc128c408727e2361!2m2!1d-70.0417404!2d41.776377!3e0' },
                        ],
                    },
                },
                description: {
                    content: 'The Spruce Hill Conservation Area is a wooded hike in Brewster that leads to a secluded beach. It\'s named after a grove of tall Norway Spruce trees, but the hike begins under a canopy of oaks and pines at the parking lot. Before the path crosses the dune to Cape Cod Bay, there is a low area with swamp azalea, viburnum, and a stand of black gum.'
                },
                features: {
                    items: ['Wooded Hike', 'Varied Flora', 'Secluded Beach', 'Bayside View'],
                },
                specifications: {
                    items: [
                        { name: 'Name:', value: 'Spruce Hill Conservation Area' },
                        { name: 'Town:', value: 'Brewster (15.2 miles from Pawnee)' },
                        { name: 'Trail Length:', value: '0.8 miles round trip' },
                        { name: 'Beach Faces:', value: 'NNW' },
                        { name: 'Directions:', value: 'See Google Maps' },
                        { name: 'Parking:', value: 'Area located off Route 6A' },
                    ],
                },
            };
        },
        mounted() {
            document.title = 'Memory | Spruce Hill';
            this.$nextTick(() => {
                window.addEventListener('resize', this.on_resize);
            });
        },
        methods: {
            on_resize() {
                this.window = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                };
            },
        },
    };
</script>

<style scoped>
    @import url('/fonts/FOREST CAMP.otf');
    
    @font-face {
        font-family: 'FOREST CAMP';
        src: url('/fonts/FOREST CAMP.otf');
    }

    .container {
        margin: 0;
        padding: 0;
        overflow: hidden;
        overflow-y: hidden;
    }

    .header {
        margin-top: 0;
        background-color: #5083c1;
    }

    .header .title {
        margin-top: 0;
        padding-top: 40px;
        font-family: 'FOREST CAMP';
        color: white;
        font-size: 50px;
        text-align: center;
    }

    .header .menu {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: center;
        padding-bottom: 40px;
    }

    .header .menu li {
        display: inline;
        padding: 10px;
    }

    .header .menu li a {
        text-decoration: underline;
        color: white;
    }

    .description {
        margin: 0;
        background-color: #fdf3d0;
        padding: 30px 15px;
        text-align: center;
    }

    .description .text {
        margin: 0;
    }

    .map img {
        width: 100%;
    }

    .details {
        background-color: #48752c;
        color: white;
    }

    .details .features {
        padding: 30px 15px;
    }

    .details .features ul {
        font-size: 18px;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }

    .details .specifications {
        padding: 0px 15px 30px 15px;
        text-align: left;
    }

    .details .specifications table {
        font-size: 18px;
    }

    .details .specifications th {
        width: 150px;
    }

    .details .hikes {
        padding: 15px;
    }

    .details .hikes .hike {
        padding-bottom: 30px;
    }

    .details .hike ul {
        font-size: 18px;
    }

</style>
